import logo from "./logo.svg";
import "./App.css";
import Index from "./Component/Layout/Index";
import AppRoutes from "./Routes/AppRoutes";

import { useSelector } from "react-redux";

function App() {
  const getAssignment = useSelector((state) =>
    state?.showtoggel?.showToggel ? state?.showtoggel?.showToggel : {}
  );
  return (
    <div class={getAssignment == "false" ? "show menu-toggle" : "show "}>
      {/* <Router> */}
      {/* <Index /> */}
      {/* { commenreducer &&
<LoadingScreen/>} */}
      <AppRoutes />
      {/* </Router> */}
    </div>
  );
}

export default App;
