import { toast } from "react-toastify";
import Api from "../api";
import {
  SHOW_TOGGEL,
  CREATE_PLAYER,
  LOADING,
  PLAYER_LIST,
  USER_LIST,
  GET_LAST_WIN,
  CREATE_MATCH,
  GET_ALL_MATCH,
  CREATE_BUYER,
  CREATE_PRODUCT,
  GET_ADMIN_NOTIFICATION,
  SLIDER_LIST,
  BUYER_LIST,
} from "../constant";
import { URL } from "../url";

const loading = (data) => {
  return { type: LOADING, payload: data };
};

const getUsersDetailsAction = (data) => {
  return { type: SHOW_TOGGEL, payload: data };
};
const createPlayerAction = (data) => {
  return { type: CREATE_PLAYER, payload: data };
};
const playerListAction = (data) => {
  return { type: PLAYER_LIST, payload: data };
};
const userListAction = (data) => {
  return { type: USER_LIST, payload: data };
};
const getLastListAction = (data) => {
  return { type: GET_LAST_WIN, payload: data };
};
const createMatchAction = (data) => {
  return { type: CREATE_MATCH, payload: data };
};
const getAllMatchAction = (data) => {
  return { type: GET_ALL_MATCH, payload: data };
};
const createProductAction = (data) => {
  return { type: CREATE_PRODUCT, payload: data };
};
const createBuyerAction = (data) => {
  return { type: CREATE_BUYER, payload: data };
};
const getAdminNotificationAction = (data) => {
  return { type: GET_ADMIN_NOTIFICATION, payload: data };
};
const sliderListAction = (data) => {
  return { type: SLIDER_LIST, payload: data };
};
const buyerListAction = (data) => {
  return { type: BUYER_LIST, payload: data };
};

export const ShowtoggelAction = (data) => {
  return async (dispatch) => {
    dispatch(getUsersDetailsAction(data));
  };
};

export const adminlogin1 = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };
      const response = await Api.post(`${URL.adminlogin1}`, data);

      if (response?.data?.status == true) {
        // console.log(response?.data?.data);
        // console.log(response?.data?.data?.token);

        localStorage.setItem("admin_token", response?.data?.data?.token);

        // dispatch(getAllMatchAction(response?.data?.data));
        // dispatch(playerListAction(response?.data?.data));
        navigate("/");
        dispatch(loading(false));
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getusers = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await Api.get(`${URL.getusers}${data}`, config);
      dispatch(userListAction(response?.data?.data));
      // localStorage.setItem("admin_token", response?.data?.data?.token);

      // dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      navigate("/");
      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getAdminNotifications = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await Api.get(`${URL.getAdminNotifications}`, config);
      dispatch(getAdminNotificationAction(response?.data?.data));
      // localStorage.setItem("admin_token", response?.data?.data?.token);

      // dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      navigate("/");
      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const allbuyer = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await Api.get(`${URL.allbuyer}${data}`, config);
      dispatch(buyerListAction(response?.data?.data));
      // localStorage.setItem("admin_token", response?.data?.data?.token);

      // dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      navigate("/");
      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const sliderList = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await Api.get(`${URL.sliderList}`, config);
      dispatch(sliderListAction(response?.data?.data));
      // localStorage.setItem("admin_token", response?.data?.data?.token);

      // dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      // navigate("/");
      dispatch(loading(false));
      // toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const deleteSliderimage = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await Api.post(`${URL.deleteSliderimage}`, data, config);
      // dispatch(sliderListAction(response?.data?.data));
      // localStorage.setItem("admin_token", response?.data?.data?.token);

      // dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));

      if (response?.data?.status == true) {
        const response2 = await Api.get(`${URL.sliderList}`, config);
        dispatch(sliderListAction(response2?.data?.data));
      }

      // navigate("/");
      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteProduct = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await Api.post(`${URL.deleteProduct}`, data, config);
      // dispatch(sliderListAction(response?.data?.data));
      // localStorage.setItem("admin_token", response?.data?.data?.token);

      // dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));

      if (response?.data?.status == true) {
        const response = await Api.get(
          `${URL.getProductDetail}${data?.pageNumber}`,
          config
        );
        dispatch(playerListAction(response?.data?.data));
      }

      // navigate("/");
      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const addSliderimage = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await Api.post(`${URL.addSliderimage}`, data, config);
      // dispatch(createBuyerAction(response?.data?.data));

      dispatch(loading(false));
      toast.success(response?.data?.message);

      if (response?.data?.status == true) {
        const response2 = await Api.get(`${URL.sliderList}`, config);
        dispatch(sliderListAction(response2?.data?.data));
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getProductDetail = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await Api.get(`${URL.getProductDetail}${data}`, config);
      dispatch(playerListAction(response?.data?.data));
      // localStorage.setItem("admin_token", response?.data?.data?.token);

      // dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      navigate("/");
      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

// dispatch(playerListAction(response?.data?.data));

export const postProduct = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await Api.post(`${URL.postProduct}`, data, config);
      dispatch(createProductAction(response?.data?.data));
      // localStorage.setItem("admin_token", response?.data?.data?.token);

      // dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      // navigate("/");

      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const addBuyer = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await Api.post(`${URL.addBuyer}`, data, config);
      dispatch(createBuyerAction(response?.data?.data));

      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const addAdminNotification = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await Api.post(
        `${URL.addAdminNotification}`,
        data,
        config
      );
      // dispatch(createBuyerAction(response?.data?.data));

      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getAllMatchAdmin = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getAllMatchAdmin}`, data);
      dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteMatch = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.deleteMatch}`, data);
      dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      // toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createMatch = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.createMatch}`, data);
      console.log(response?.data?.data);
      dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createPlayer = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.createPlayer}`, data);
      dispatch(createPlayerAction(response?.data?.data));
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const playerList = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.playerList}`, data);
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getLastWin = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getLastWin}`, data);
      dispatch(getLastListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getPlayersBysokect = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getPlayersBysokect}`, data);
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const playerDelete = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.playerDelete}`, data);
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const playerSreach = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.playerSreach}`, data);
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const playerEditName = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.playerEditName}`, data);
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const editplayerImage = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.editplayerImage}`, data);
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getUserListAdmin = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getUserListAdmin}`, data);
      dispatch(userListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const searchUser = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.searchUser}`, data);
      dispatch(userListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteUserAdmin = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.deleteUserAdmin}`, data);
      dispatch(userListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateUserAdmin = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.updateUserAdmin}`, data);
      dispatch(userListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
