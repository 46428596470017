import {
  SHOW_TOGGEL,
  PLAYER_LIST,
  USER_LIST,
  GET_LAST_WIN,
  GET_ALL_MATCH,
  CREATE_PRODUCT,
  CREATE_BUYER,
  GET_ADMIN_NOTIFICATION,
  SLIDER_LIST,
  BUYER_LIST,
} from "../constant";

const initialState = {
  loading: false,
  showToggel: {},
  player_list: {},
  user_list: {},
  get_win_list: {},
  create_product: {},
  create_buyer: {},
  get_all_match: [],
  slider_list: [],
  buyer_list: [],
  get_admin_notification: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOGGEL:
      return { ...state, showToggel: action.payload };

    case PLAYER_LIST:
      return { ...state, player_list: action.payload };
    case USER_LIST:
      return { ...state, user_list: action.payload };
    case GET_LAST_WIN:
      return { ...state, get_win_list: action.payload };
    case GET_ALL_MATCH:
      return { ...state, get_all_match: action.payload };
    case CREATE_PRODUCT:
      return { ...state, create_product: action.payload };
    case CREATE_BUYER:
      return { ...state, create_buyer: action.payload };
    case GET_ADMIN_NOTIFICATION:
      return { ...state, get_admin_notification: action.payload };
    case SLIDER_LIST:
      return { ...state, slider_list: action.payload };
    case BUYER_LIST:
      return { ...state, buyer_list: action.payload };

    default:
      return state;
  }
};
