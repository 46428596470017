import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import firebase from "firebase";
import { db, storage } from "../../../Firebase";
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { TableHeader, Pagination, Search } from "../../Table/DataTable";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { ShoetoggelAction } from "../../../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../../Redux/common/url";

function Poll() {
  const navigate = useNavigate();
  const ref = useRef();
  const dispatch = useDispatch();

  const [categoryValue, setcategoryValue] = useState({});
  const [deleteCategory, setdeleteCategory] = useState({});

  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);

  const [filterUserview, setfilterUserview] = useState({});

  const [comments, setComments] = useState([]);

  const [file, setFile] = useState(null);

  const [photoIndex, setphotoIndex] = useState(0);

  const [isOpen, setisOpen] = useState(false);

  const get_all_match = useSelector((state) =>
    state?.showtoggel?.get_admin_notification
      ? state?.showtoggel?.get_admin_notification
      : []
  );

  // console.log(get_all_match);

  const imageviews = (e) => {
    setisOpen(true);
    setphotoIndex(e);
  };

  const handleImage = (event) => {
    setFile(event.target.files[0]);
  };

  const [Matchs, setMatchs] = useState([]);

  // const reset = () => {
  //   ref.current.value = "";
  // };

  // console.log(comments);

  useEffect(() => {
    db.collection("poll")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const oderdetails = snapshot.docs.map((e) => {
          const data = e.data();
          data.id = e.id;
          return data;
        });

        setComments(oderdetails);
      });
    dispatch(ShoetoggelAction.getAdminNotifications());
    // reset();
    return () => {};
  }, []);

  const hendledelete = async (e) => {
    // deletePredication

    dispatch(ShoetoggelAction.deleteMatch({ id: deleteCategory }));
    // reset();
    return () => {};
    // const res = await db
    //   .collection("poll")
    //   .doc(deleteCategory.toString())
    //   .delete();
    // toast.success("deleted ");
  };

  const hendleitemsPerPage = (e) => {
    setitemsPerPage(parseInt(e.target.value));
  };

  const hendleCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcategoryValue({ ...categoryValue, [name]: value });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    // { name: "No", field: "id", sortable: true },
    // { name: "Image", field: "image", sortable: false },
    { name: "Image", field: "image", sortable: false },
    { name: "Contact", field: "contact", sortable: false },
    { name: "link", field: "link", sortable: false },
    { name: "description", field: "description", sortable: false },
    // { name: "match_date", field: "match_date", sortable: false },
    // { name: "match_time", field: "match_time", sortable: false },
    // { name: "count_a", field: "count_a", sortable: false },
    // { name: "count_b", field: "count_b", sortable: false },
    // { name: "tie", field: "tie", sortable: false },
    // { name: "Date", field: "date", sortable: false },
    // { name: "Action", field: "action", sortable: false },
  ];

  // <td>{comment?.match_id}</td>
  // <td>{comment?.series}</td>
  // <td>{comment?.team_a_name}</td>
  // <td>{comment?.team_b_name}</td>
  // <td>{comment?.match_date}</td>
  // <td>{comment?.match_time}</td>
  // <td>{comment?.count_a}</td>
  // <td>{comment?.count_b}</td>
  // <td>{comment?.tia}</td>
  const headers2 = [
    // { name: "No", field: "id", sortable: true },
    // { name: "Image", field: "image", sortable: false },
    { name: "Action", field: "action", sortable: false },
    { name: "Match Id", field: "match_id", sortable: true },
    { name: "Matchs", field: "matchs", sortable: true },
    { name: "Series", field: "series", sortable: true },
    { name: "Team A", field: "team_a", sortable: false },
    { name: "Team B", field: "team_b", sortable: false },
    { name: "Match Type", field: "match_type", sortable: false },
  ];

  // <td>{comment?.match_id}</td>
  // <td>{comment?.matchs}</td>
  // <td>{comment?.series}</td>
  // <td>{comment?.team_a}</td>
  // <td>{comment?.team_b}</td>
  // <td>{comment?.match_type}</td>

  const commentsData = useMemo(() => {
    let computedComments = get_all_match;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.series.toLowerCase().includes(search.toLowerCase()) ||
          comment.team_a_name.toLowerCase().includes(search.toLowerCase()) ||
          comment.team_b_name.toLowerCase().includes(search.toLowerCase()) ||
          comment.match_date.toLowerCase().includes(search.toLowerCase()) ||
          comment.match_time.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    // console.log(computedComments);
    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [get_all_match, currentPage, search, sorting, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = event.selected % (get_all_match.length / 2);
    setCurrentPage(newOffset + 1);
    setItemOffset(newOffset * itemsPerPage);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setPageCount(Math.ceil(get_all_match.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, get_all_match]);

  async function sendMessagesss(e) {
    const filterMatch =
      Matchs &&
      Matchs?.filter((data) => {
        return data?.match_id == e;
      });

    if (filterMatch?.length == 1) {
      const alredMatch =
        get_all_match &&
        get_all_match?.filter((data) => {
          return data?.match_id == e;
        });

      if (alredMatch?.length == 1) {
        toast.error("Already Add");
      } else {
        const filnal = filterMatch[0];
        const idDoc = filterMatch[0]?.match_id;

        const abc = db.collection("poll").doc(idDoc.toString()).set({
          match_id: filnal?.match_id,
          match_date: filnal?.match_date,
          match_time: filnal?.match_time,
          series: filnal?.series,
          series_id: filnal?.series_id,
          team_a_name: filnal?.team_a,
          team_b_name: filnal?.team_b,
          team_a_img: filnal?.team_a_img,
          team_b_img: filnal?.team_b_img,
          team_a_short: filnal?.team_a_short,
          team_b_short: filnal?.team_b_short,
          venue: filnal?.venue,
          count_a: 0,
          count_b: 0,
          tie: 0,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        setcategoryValue({ title: "", message: "", content: "" });
        toast.success("poll successfully Add");
      }
    }
  }
  const createMatchList = (e) => {
    // console.log(e);

    // console.log(new Date(e?.date_wise));
    // console.log(e?.date_wise);

    const getdated = e?.date_wise.split(",")[0];

    // console.log(getdated);
    const dateToArray = getdated.split(" ");

    // console.log(dateToArray);

    // console.log(dateFormat(e?.date_wise, "dddd, mmmm dS, yyyy, h:MM:ss TT"));

    var month1 = dateToArray[1].toLowerCase();
    var months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];
    month1 = months.indexOf(month1);

    // console.log(month1 + 1);

    const LastMont = month1 + 1;

    const finalmonth = LastMont > 9 ? LastMont : "0" + LastMont;

    const time = e?.match_time?.split(" ")[0];

    const dateFinal =
      dateToArray[2] +
      "-" +
      finalmonth +
      "-" +
      dateToArray[0] +
      " " +
      e?.match_time;

    // console.log(dateFinal);
    // const acd = "Sep";

    dispatch(
      ShoetoggelAction.createMatch({
        match_id: e?.match_id,
        venue: e?.venue,
        teamAShort: e?.team_a_short,
        teamBShort: e?.team_b_short,

        ImgUrlTeamA: e?.team_a_img,
        ImgUrlTeamB: e?.team_b_img,
        Leauge: e?.series,
        MatchType: e?.match_type,

        StartDate: dateFinal,
        StartTime: e?.match_time,
        date_wise: e?.date_wise,
        match_date: e?.match_date,
        TeamA: e?.team_a,
        TeamB: e?.team_b,
      })
    );
    // reset();
    return () => {};
  };

  const cretePollShow = (val) => {
    if (val === "show") {
      document.getElementById("roomView").style.display = "none";
      document.getElementById("roomsShow").style.display = "block";
    }
    if (val === "cencel") {
      document.getElementById("roomView").style.display = "block";
      document.getElementById("roomsShow").style.display = "none";
    }
  };

  const [image, setImage] = useState(null);
  const [notificationData, setNotificationData] = useState({
    description: "",
    link: "",
    contact: "",
  });

  // const get_all_match = useSelector((state) =>
  //   state?.showtoggel?.get_all_match ? state?.showtoggel?.get_all_match : []
  // );

  const hendleSendNotification = (e) => {
    const { name, value } = e.target;
    setNotificationData({ ...notificationData, [name]: value });
  };

  console.log(notificationData);

  const submitData = (e) => {
    const data = new FormData();

    if (!notificationData?.description) {
      toast.error("description required");
    } else if (image == null) {
      toast.error("image required");
    } else {
      data.append("description", notificationData?.description);
      data.append("link", notificationData?.link);
      data.append("contact", notificationData?.contact);

      data.append("image", image);

      // data.append("password", otp);

      dispatch(ShoetoggelAction.addAdminNotification(data));
      reset();
      setNotificationData({
        description: "",
        link: "",
        contact: "",
      });
      setImage(null);
      return () => {};
    }
  };

  const reset = () => {
    ref.current.value = "";
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to={"/"}> Deshbosrd</Link>/{" "}
                <a className="pageAcctive">Poll</a>
              </li>
            </ol>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card" id="roomView">
                <div className="card-header">
                  <h4 className="card-title">Poll</h4>

                  <div className="addPlus">
                    <a
                      href="#category"
                      className="login_button newQue"
                      // data-toggle="modal"

                      onClick={(e) => {
                        cretePollShow("show");
                      }}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    id="example3_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      className="dataTables_length category"
                      id="example3_length"
                    >
                      <label>
                        Show{" "}
                        <div className="dropdown category">
                          <select
                            name="example3_length"
                            aria-controls="example3"
                            className=""
                            tabIndex="-98"
                            onChange={(e) => {
                              hendleitemsPerPage(e);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value={"25"}>25</option>
                            <option value={"50"}>50</option>
                            <option value={"100"}>100</option>
                          </select>
                        </div>{" "}
                        entries
                      </label>
                    </div>
                    <div
                      id="example3_filter"
                      className="dataTables_filter category"
                    >
                      <Search
                        onSearch={(value) => {
                          setSearch(value);
                          setCurrentPage(1);
                        }}
                      />
                    </div>

                    <div className="row w-100">
                      <div className="col mb-3 col-12 text-center">
                        <div className="row">
                          <div className="col-md-6"></div>
                          <div className="col-md-6 d-flex flex-row-reverse"></div>
                        </div>

                        <table className="table table-striped">
                          <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                          <tbody>
                            {commentsData.map((comment, i) => {
                              console.log(comment);
                              const createDate = dateFormat(
                                comment?.date?.toDate(),
                                "dd-mm-yyyy"
                              );

                              return (
                                <tr key={i}>
                                  <td
                                    className="GallaryImg"
                                    onClick={(e) => {
                                      imageviews(i);
                                    }}
                                  >
                                    <img
                                      src={URL.API_BASE_URL + comment?.image}
                                      alt=""
                                    />
                                  </td>

                                  <td>{comment?.contact}</td>
                                  <td>{comment?.link}</td>
                                  <td>{comment?.description}</td>
                                  {/* <td>{comment?.TeamB}</td>
                                  <td>{comment?.match_date}</td>
                                  <td>{comment?.StartTime}</td> */}
                                  {/* <td>{comment?.count_a}</td>
                                  <td>{comment?.count_b}</td>
                                  <td>{comment?.tie}</td> */}
                                  {/* <td>{createDate}</td> */}
                                  {/* <td>
                                    <div className="d-flex">
                                
                                      <a
                                        href="#delete"
                                        data-toggle="modal"
                                        className="btn btn-danger shadow btn-xs sharp"
                                        onClick={(e) => {
                                          setdeleteCategory(comment?.id);
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </a>
                                    </div>
                                  </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      className="dataTables_info category"
                      id="example3_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {itemOffset} to{" "}
                      {get_all_match?.length < itemOffset + itemsPerPage
                        ? get_all_match?.length
                        : itemOffset + itemsPerPage - 1}{" "}
                      of{" "}
                      {/* {getParentCategory && getParentCategory?.length} */}{" "}
                      {get_all_match?.length} entries
                    </div>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      className="dataTables_paginate paging_simple_numbers category"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>

              <div id="roomsShow" className="card" style={{ display: "none" }}>
                <div className="card-header">
                  <h4 className="card-title">Poll</h4>

                  <div className="addPlus">
                    <a
                      href="#category"
                      className="login_button newQue"
                      // data-toggle="modal"

                      onClick={(e) => {
                        cretePollShow("cencel");
                      }}
                    >
                      <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>

                <div style={{ padding: "25px" }}>
                  <input
                    name="description"
                    type="text"
                    className="form-control input-default mt-3 "
                    placeholder="Enter description"
                    value={notificationData?.description}
                    onChange={(e) => {
                      hendleSendNotification(e);
                    }}
                  />
                  <input
                    name="link"
                    type="text"
                    className="form-control input-default mt-3 "
                    placeholder="Enter link"
                    value={notificationData?.link}
                    onChange={(e) => {
                      hendleSendNotification(e);
                    }}
                  />
                  <input
                    name="contact"
                    type="number"
                    className="form-control input-default mt-3 "
                    value={notificationData?.contact}
                    placeholder="Enter contact"
                    onChange={(e) => {
                      hendleSendNotification(e);
                    }}
                  />
                  <input
                    type="file"
                    className="form-control input-default mt-3 "
                    name="image"
                    ref={ref}
                    accept="image/*"
                    onChange={(event) => {
                      setImage(event.target.files[0]);
                    }}
                  />
                  <div className="submitBtn">
                    <button
                      type="button"
                      onClick={(e) => {
                        submitData(e);
                      }}
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={URL.API_BASE_URL + get_all_match[photoIndex].image}
          nextSrc={get_all_match[(photoIndex + 1) % get_all_match.length]}
          prevSrc={
            get_all_match[
              (photoIndex + get_all_match.length - 1) % get_all_match.length
            ]
          }
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setphotoIndex(
              (photoIndex + get_all_match.length - 1) % get_all_match.length
            )
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % get_all_match.length)
          }
        />
      )}

      <div className="bs-example">
        <div id="category" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  poll
                </h5>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body" style={{ padding: "26px" }}>
                <div className="assignment_form loader-parent">
                  <form>
                    <div className="form-group col-md-12">
                      <input
                        name="title"
                        type="text"
                        value={categoryValue?.title}
                        className="form-control input-default "
                        placeholder="Enter Title"
                        onChange={(e) => {
                          hendleCategoryAdd(e);
                        }}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <input
                        name="message"
                        type="text"
                        value={categoryValue?.message}
                        className="form-control input-default "
                        placeholder="Enter message"
                        onChange={(e) => {
                          hendleCategoryAdd(e);
                        }}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      {/* <DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                // showTimeSelect
                                                // showTimeSelectOnly
                                                // timeIntervals={15}
                                                className="form-control input-default "
                                                timeCaption="Time"
                                                placeholderText="Opne Time"
                                            // dateFormat="h:mm aa"
                                            /> */}
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        timeFormat="HH:mm:ss"
                        className="form-control"
                        minDate={new Date()}
                        placeholderText="Date & Time"
                        dateFormat="yyyy-MM-dd HH:mm:ss"
                      />
                    </div>
                    <div className="submitBtn">
                      <button
                        type="button"
                        onClick={(e) => {
                          sendMessagesss(e);
                        }}
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>

                  <div
                    className="loader-bx lodding "
                    style={{ display: "none" }}
                  >
                    <img src="img/lodder.gif" alt="loader" className="center" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bs-example">
        <div id="delete" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div>
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Are you sure want to delete Poll
                </h5>
                <div className="deleteBtn">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    onClick={hendledelete}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Poll;
