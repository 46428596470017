import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import firebase from "firebase";
import { db, storage } from "../../../Firebase";
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { TableHeader, Pagination, Search } from "../../Table/DataTable";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { ShoetoggelAction } from "../../../Redux/common/action";

function Prediction() {
  const navigate = useNavigate();
  const ref = useRef();

  const dispatch = useDispatch();

  const [categoryValue, setcategoryValue] = useState({});
  const [deleteCategory, setdeleteCategory] = useState({});

  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);

  const [filterUserview, setfilterUserview] = useState({});

  const [file, setFile] = useState(null);

  const [photoIndex, setphotoIndex] = useState(0);

  const [isOpen, setisOpen] = useState(false);
  const [pageNo, setpageNo] = useState(1);

  const imageviews = (e) => {
    setisOpen(true);
    setphotoIndex(e);
  };

  // getUserListAdmin

  const userList = useSelector((state) =>
    state?.showtoggel?.user_list ? state?.showtoggel?.user_list : {}
  );

  // console.log(userList);

  useEffect(() => {
    dispatch(ShoetoggelAction.getusers(1));

    return () => {};
  }, []);

  const handleImage = (event) => {
    setFile(event.target.files[0]);
  };

  async function sendMessagesss(e) {
    e.preventDefault();

    // const abc = db.collection("Prediction").add({
    //   message: categoryValue?.message,
    //   title: categoryValue?.title,
    //   date: startDate,
    //   // image: url,
    //   createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    // });
    // setcategoryValue({ title: "", message: "", content: "" });
    // toast.success("prediction successfully Add");
  }

  const reset = () => {
    ref.current.value = "";
  };

  useEffect(() => {
    // db.collection("Prediction")
    //   .orderBy("createdAt", "desc")
    //   .onSnapshot((snapshot) => {
    //     const oderdetails = snapshot.docs.map((e) => {
    //       const data = e.data();
    //       data.id = e.id;
    //       return data;
    //     });
    //     // setComments(oderdetails);
    //   });
  }, []);

  const hendledelete = async (e) => {
    // deleteUserAdmin

    dispatch(
      ShoetoggelAction.deleteUserAdmin({
        pageNumber: pageNo,
        id: deleteCategory,
      })
    );

    return () => {};

    // const res = await db.collection("Prediction").doc(deleteCategory).delete();
    // toast.success("deleted ");
  };

  const hendleitemsPerPage = (e) => {
    setitemsPerPage(parseInt(e.target.value));
  };

  const hendleCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcategoryValue({ ...categoryValue, [name]: value });
  };

  const comments = userList?.data ? userList?.data : [];

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    // { name: "No", field: "id", sortable: true },
    // { name: "Image", field: "image", sortable: false },
    { name: "Name", field: "name", sortable: true },
    { name: "Number", field: "mobile", sortable: true },
    // { name: "Id of User", field: "IdofUser", sortable: true },
    { name: "Date", field: "date", sortable: false },
    // { name: "Action", field: "action", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.message.toLowerCase().includes(search.toLowerCase()) ||
          comment.title.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  const [userSearch, setuserSearch] = useState("");

  const handlePageClick = (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1;

    setpageNo(data);

    // if (!userSearch) {
    dispatch(ShoetoggelAction.getusers(data));

    return () => {};
    // } else {
    //   dispatch(
    //     ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    //   );

    //   return () => {};
    // }
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setPageCount(Math.ceil(userList?.total / itemsPerPage));
  }, [itemOffset, itemsPerPage, comments]);

  const hendlesearch = (e) => {
    // searchUser

    setuserSearch(e);
    dispatch(ShoetoggelAction.searchUser({ pageNumber: 1, key: e }));

    return () => {};
  };

  //   updateUserAdmin

  const [userEditdata, setuserEditdata] = useState({});

  const editHendle = (e) => {
    setuserEditdata(e);
  };

  //   console.log(userEditdata);
  userEditdata.pageNumber = pageNo;

  const hendleEdit = (e) => {
    const { name, value } = e.target;

    setuserEditdata({ ...userEditdata, [name]: value });
  };

  const userEditSubmit = (e) => {
    dispatch(ShoetoggelAction.updateUserAdmin(userEditdata));

    return () => {};
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to={"/"}> Deshbosrd</Link>/{" "}
                <a className="pageAcctive">Users</a>
              </li>
            </ol>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Users</h4>

                  {/* <div className="addPlus">
                    <a
                      href="#category"
                      className="login_button newQue"
                      data-toggle="modal"
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </a>
                  </div> */}
                </div>
                <div className="card-body">
                  <div
                    id="example3_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    {/* <div
                      className="dataTables_length category"
                      id="example3_length"
                    >
                      <label>
                        Show{" "}
                        <div className="dropdown category">
                          <select
                            name="example3_length"
                            aria-controls="example3"
                            className=""
                            tabIndex="-98"
                            onChange={(e) => {
                              hendleitemsPerPage(e);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value={"25"}>25</option>
                            <option value={"50"}>50</option>
                            <option value={"100"}>100</option>
                          </select>
                        </div>{" "}
                        entries
                      </label>
                    </div> */}
                    <div
                      id="example3_filter"
                      className="dataTables_filter category"
                    >
                      {/* <input
                        placeholder="Search"
                        onChange={(e) => {
                          hendlesearch(e.target.value);
                        }}
                      /> */}

                      {/* <Search
                        onSearch={(value) => {
                          setSearch(value);
                          setCurrentPage(1);
                        }}
                      /> */}
                    </div>

                    <div className="row w-100">
                      <div className="col mb-3 col-12 text-center">
                        <div className="row">
                          <div className="col-md-6"></div>
                          <div className="col-md-6 d-flex flex-row-reverse"></div>
                        </div>

                        <table className="table table-striped">
                          <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                          <tbody>
                            {commentsData.map((comment, i) => {
                              const createDate = dateFormat(
                                comment?.created_at,
                                "dd-mm-yyyy"
                              );

                              return (
                                <tr key={i}>
                                  {/* <td className="GallaryImg" onClick={(e) => { imageviews(i) }} ><img src={comment?.image} alt="" /></td> */}

                                  <td>{comment?.name}</td>
                                  <td>{comment?.mobile}</td>
                                  {/* <td>{comment?.IdofUser}</td> */}
                                  <td>{createDate}</td>
                                  {/* <td>
                                    <div className="d-flex">
                                      <a
                                        href="#editUSer"
                                        data-toggle="modal"
                                        onClick={(e) => {
                                          editHendle(comment);
                                        }}
                                        className="btn btn-primary shadow btn-xs sharp mr-1"
                                      >
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>
                                      </a>

                                      <a
                                        href="#delete"
                                        data-toggle="modal"
                                        className="btn btn-danger shadow btn-xs sharp"
                                        onClick={(e) => {
                                          setdeleteCategory(comment?.id);
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </a>
                                    </div>
                                  </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      className="dataTables_info category"
                      id="example3_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {itemOffset} to{" "}
                      {comments?.length < itemOffset + itemsPerPage
                        ? comments?.length
                        : itemOffset + itemsPerPage - 1}{" "}
                      of{" "}
                      {/* {getParentCategory && getParentCategory?.length} */}{" "}
                      {comments?.length} entries
                    </div>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      className="dataTables_paginate paging_simple_numbers category"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={comments[photoIndex].image}
          nextSrc={comments[(photoIndex + 1) % comments.length]}
          prevSrc={
            comments[(photoIndex + comments.length - 1) % comments.length]
          }
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + comments.length - 1) % comments.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % comments.length)
          }
        />
      )}

      <div className="bs-example">
        <div id="category" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Prediction
                </h5>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body" style={{ padding: "26px" }}>
                <div className="assignment_form loader-parent">
                  <form>
                    {/* <div className="form-group col-md-12">
                                            <input

                                                type="file"
                                                className="form-control input-default "
                                                name="image"

                                                ref={ref}
                                                accept="image/*"
                                                onChange={
                                                    handleImage}
                                            />
                                        </div> */}

                    <div className="form-group col-md-12">
                      <input
                        name="title"
                        type="text"
                        value={categoryValue?.title}
                        className="form-control input-default "
                        placeholder="Enter Title"
                        onChange={(e) => {
                          hendleCategoryAdd(e);
                        }}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <input
                        name="message"
                        type="text"
                        value={categoryValue?.message}
                        className="form-control input-default "
                        placeholder="Enter message"
                        onChange={(e) => {
                          hendleCategoryAdd(e);
                        }}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      {/* <DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                // showTimeSelect
                                                // showTimeSelectOnly
                                                // timeIntervals={15}
                                                className="form-control input-default "
                                                timeCaption="Time"
                                                placeholderText="Opne Time"
                                            // dateFormat="h:mm aa"
                                            /> */}
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        timeFormat="HH:mm:ss"
                        className="form-control"
                        minDate={new Date()}
                        placeholderText="Date & Time"
                        dateFormat="yyyy-MM-dd HH:mm:ss"
                      />
                    </div>
                    <div className="submitBtn">
                      <button
                        type="button"
                        onClick={(e) => {
                          sendMessagesss(e);
                        }}
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>

                  <div
                    className="loader-bx lodding "
                    style={{ display: "none" }}
                  >
                    <img src="img/lodder.gif" alt="loader" className="center" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bs-example">
        <div id="delete" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div>
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Are you sure want to delete Prediction
                </h5>
                <div className="deleteBtn">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    onClick={hendledelete}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* edit user */}

      <div className="bs-example">
        <div id="editUSer" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div>
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Edit user
                </h5>

                <div style={{ padding: "8px 27px" }}>
                  <input
                    placeholder="Enter name"
                    className="form-control"
                    name="Name"
                    value={userEditdata?.Name}
                    onChange={(e) => {
                      hendleEdit(e);
                    }}
                  />
                </div>
                <div className="deleteBtn">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    onClick={userEditSubmit}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prediction;
