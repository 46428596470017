export const LOADING = "LOADING";
//user constant
export const SHOW_TOGGEL = "SHOW_TOGGEL";
export const CREATE_PLAYER = "CREATE_PLAYER";
export const PLAYER_LIST = "PLAYER_LIST";
export const USER_LIST = "USER_LIST";
export const GET_LAST_WIN = "GET_LAST_WIN";
export const CREATE_MATCH = "CREATE_MATCH";
export const GET_ALL_MATCH = "GET_ALL_MATCH";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_BUYER = "CREATE_BUYER";
export const GET_ADMIN_NOTIFICATION = "GET_ADMIN_NOTIFICATION";
export const SLIDER_LIST = "SLIDER_LIST";
export const BUYER_LIST = "BUYER_LIST";
