export const URL = {
  // dev
  // API_BASE_URL:"http://192.168.0.8:3000",

  // creato
  API_BASE_URL: "https://pvcbackend.asuneed.in/",

  // API_BASE_URL: "http://192.168.0.25:3004",
  // API_BASE_URL: "http://aurrateck.com:3004",

  adminlogin1: "api/adminlogin",
  addBuyer: "api/addBuyer",
  getusers: "api/getusers?page=",
  allbuyer: "api/allbuyer?page=",
  sliderList: "api/sliderList",
  addSliderimage: "api/addSliderimage",
  deleteProduct: "api/deleteProduct",
  deleteSliderimage: "api/deleteSliderimage",

  getProductDetail: "api/getProductDetail?page=",
  postProduct: "api/postProduct",

  addAdminNotification: "api/addAdminNotification",
  getAdminNotifications: "api/getAdminNotifications",

  adminLogin: "/dhadmin/adminLogin",
  createPlayer: "/apiPublic/createPlayer",
  playerList: "/apiPublic/playerList",
  playerDelete: "/apiPublic/playerDelete",
  playerSreach: "/apiPublic/playerSreach",
  playerEditName: "/apiPublic/playerEditName",
  editplayerImage: "/apiPublic/editplayerImage",
  getUserListAdmin: "/apiPublic/getUserListAdmin",
  searchUser: "/apiPublic/searchUser",
  deleteUserAdmin: "/apiPublic/deleteUserAdmin",
  updateUserAdmin: "/apiPublic/updateUserAdmin",
  getPlayersBysokect: "/apiPublic/getPlayersBysokect",
  getLastWin: "/apiPublic/getLastWin",
  createMatch: "/apiPublic/createMatch",
  getAllMatchAdmin: "/apiPublic/getAllMatchAdmin",
  deletePredication: "/apiPublic/deletePredication",
  deleteMatch: "/apiPublic/deleteMatch",
};
