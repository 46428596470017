import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../Component/Dashboard/Dashboard";

import Error404 from "../Component/Layout/Error/Error404";

import Prediction from "../Component/Layout/Flavors/Prediction";

// import Test1 from "../Component/Layout/Flavors/Test1";
import Images from "../Component/Layout/Images/Images";
import Slider from "../Component/Layout/Images/Slider";
// import Index from "../Component/Layout/Index";
import Player from "../Component/Layout/Player/Player";
import Poll from "../Component/Layout/Poll/Poll";
import Login from "../Component/Login/Login";
import AuthAdmin from "./AuthAdmin";

function AppRoutes() {
  return (
    <div>
      {/* <Switch>
                <ProtectedRoute exact path="/" component={Dashboard} />

                <ProtectedRoute exact path="/prediction" component={Prediction} />

                <ProtectedRoute exact path="/gallery" component={Images} />


                <LoginProtectRoutes exact path="/login" component={Login} />

                <Route exact path="" component={Error404} />
            </Switch> */}

      <BrowserRouter>
        {/* <Index/> */}
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/" element={<Dashboard />} /> */}
          <Route path="/" element={<AuthAdmin cmp={Dashboard} />} />
          <Route path="/users" element={<AuthAdmin cmp={Prediction} />} />
          {/* <Route path="/test/:id" element={<AuthAdmin cmp={Test1} />} /> */}
          <Route path="/product" element={<AuthAdmin cmp={Player} />} />
          <Route path="/buyer" element={<AuthAdmin cmp={Images} />} />
          <Route path="/slider" element={<AuthAdmin cmp={Slider} />} />
          <Route path="/notification" element={<AuthAdmin cmp={Poll} />} />
          {/* <Route path="/gallery" element={<Images />} /> */}
          <Route path="" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AppRoutes;
